<template>
  <v-container>
    <v-row class="mx-2">
      <v-btn @click.native="Dialog = true" class="primary">Add</v-btn>
      <v-col v-if="record" cols="12" sm="12" md="12">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="Headers"
          :items="ItemCollection"
          :search="search"
          :options.sync="options"
          :server-items-length="totalItems"
          class="elevation-1"
          :server-search="true"
        >
          <template v-slot:item.created_at="{ item }">{{
            item.created_at | moment("LLL")
          }}</template>
          <template v-slot:item.Active="{ item }">
            {{ item.Active ? "Active" : "In-Active" }}
          </template>

          <template v-slot:item.id="props">
            <v-btn
              @click="removeItem(props.item.id, '/assign_bpartners')"
              color="white"
              plain
              small
            >
              <v-icon color="red">mdi-trash-can</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <!-- one item selection modal -->
      <!-- <v-dialog v-model="itemDialog" width="950">
        <v-toolbar dark color="primary">
          <v-toolbar-title>List of Items</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="itemDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card-title>
                    <v-text-field
                      hide-details
                      v-model="searchItem"
                      append-icon="mdi-magnify"
                      label="Select using Item Code or Item Name"
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    dense
                    @item-selected="clickedItem"
                    :items-per-page="itemsPerPage"
                    v-model="selectedItems"
                    :headers="itemMasterHeaders"
                    :items="ItemMasterData"
                    item-key="id"
                    show-select
                    :options.sync="options"
                    :server-items-length="totalItems"
                    class="elevation-1"
                    :server-search="true"
                  ></v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog> -->
      <!-- end of item modal -->
      <!-- one item selection modal -->
      <v-dialog v-model="Dialog" width="950">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Item Assignment</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="Dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :items="ItemMaster"
                    search-input="true"
                    item-value="ItemCode"
                    :item-text="(item) => item.ItemCode + '  -  ' + item.ItemName"
                    outlined
                    multiple
                    search="search"
                    v-model="record.ItemCode"
                    label="Items Master"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-btn color="accent" @click="AssignItems" :loading="loader">
                    <v-icon left>mdi-content-save</v-icon>Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- end of item modal -->
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    record: {},
    loader: false,
    ItemMaster: [],
    ItemCollection: [],
    Payment: {},
    search: "",
    searchWhs: null,
    Dialog: false,
    Headers: [
      { text: "Item Code", value: "ItemCode" },
      { text: "Item Name", value: "item.ItemName" },
      { text: "Active", value: "item.frozenFor" },
      { text: "Created On", value: "created_at" },
      { text: "Actions", value: "id" },
    ],
    options: {
      rowsPerPageOptions: [10, 20, 30],
      itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 10,
      page: 1,
    },
    totalItems: 0,
  }),

  watch: {
    options: {
      handler() {
        this.ItemsCollection();
      },
      deep: true,
    },
  },
  methods: {
    ItemsCollection() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch(
          "get",
          `/employee/${this.$route.params.empID}/itemsCollection?page=${this.options.page}&per_page=${this.options.itemsPerPage}`
        )
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.ItemCollection = res.ResponseData.data;
            self.options.page = res.ResponseData.current_page;
            self.options.itemsPerPage = res.ResponseData.per_page;
            self.totalItems = res.ResponseData.total;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          self.loading = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },

    AssignItems() {
      let data = this.record;

      const url = `/assign_items/${this.$route.params.empID}`;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            location.reload();
          } else {
            self.loading = false;
            this.$refs.snackbar.show(res.ResultDesc, "red");
          }
          this.Dialog = false;
        })
        .catch((err) => {
          self.$store.commit("loader", false);
          self.$refs.snackbar.show(err.response?.data?.ResultDesc, "red");
        });
    },
    getItemsMaster() {
      this.itemDialog = true;
      const self = this;
      this.loading = true;
      let url = `/item_masterdata?page=${this.options.page}&per_page=${this.options.itemsPerPage}`;

      if (this.searchItem && this.searchItem != undefined) {
        url += `&search=${this.searchItem}`;
      }

      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.ItemMaster = res.ResponseData.data;
          self.options.page = res.ResponseData.current_page;
          self.options.itemsPerPage = res.ResponseData.per_page;
          self.totalItems = res.ResponseData.total;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    removeItem(id, baseurl) {
      var userResponse = confirm(
        "Are you sure you want to delete? This action cannot be undone."
      );
      if (userResponse === true) {
        const url = `${baseurl}/${id}`;
        const self = this;
        self.$store
          .dispatch("remove", url)
          .then((res) => {
            if (res.ResultCode == 1200) {
              location.reload();
            } else {
              self.loading = false;
              this.$refs.snackbar.show(res.ResultDesc, "red");
            }
            this.Dialog = false;
            this.Dialog = false;
          })
          .catch((err) => {
            self.$store.commit("loader", false);
            self.$refs.snackbar.show(err.response?.data?.ResultDesc, "red");
          });
      } else {
        alert("Operation Cancelled.");
      }
    },
  },
  created() {
    this.getItemsMaster();
    this.ItemsCollection();
  },
};
</script>
